import AbstractContract from "./abstractContract";

export default class NftAbstractContract extends AbstractContract {

    async ownerOf(nftId) {
        await this._ensureContractInitialized();
        return (await this._contract.methods.ownerOf(nftId).call());
    }

    async isApprovedForAll(approveForAddress) {
        await this._ensureContractInitialized();
        return (await this._contract.methods.isApprovedForAll(this.account, approveForAddress).call());
    }

    async approveForId(approveForAddress, nftId) {
        await this._ensureContractInitialized();
        return (
            await this._contract.methods
                .approve(approveForAddress, nftId)
                .send(await this._sendParams())
                .catch((error) => {
                    this.handleTxError(error);
                })
        );
    }

    async approveAllFor(approveForAddress) {
        await this._ensureContractInitialized();
        return (
            await this._contract.methods
                .setApprovalForAll(approveForAddress, true)
                .send(await this._sendParams())
                .catch((error) => {
                    this.handleTxError(error);
                })
        );
    }

    async transfer(toAddress, nftId) {
        await this._ensureContractInitialized();
        return (
            await this._contract.methods
                .transferFrom(this.account, toAddress, nftId)
                .send(await this._sendParams())
                .catch((error) => {
                    this.handleTxError(error);
                })
        );
    }
}
